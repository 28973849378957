import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Polyline } from "react-leaflet";
import { Line as LineType, LinePoint } from "src/types/Types";

import useArc from "src/apis/lines/useArc";

type LineProps = {
  line: LineType;
};

export const Line = (props: LineProps) => {
  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc(100);
  const defaultColor = "#3388ff";

  const { line } = props;
  const [linePoints, setLinePoints] = useState<LinePoint[]>([]);

  useEffect(() => {
    const linePoints = line?.linePoints || [];
    setLinePoints(linePoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  }, [line]);

  useEffect(() => {
    setSortedLinePointsForExpansion(linePoints);
  }, [linePoints]);

  return (
    <Polyline
      key={`${line.id}${line.color ? line.color : ""}`}
      pathOptions={{ color: line.color ? line.color : defaultColor, dashArray: line.dashArray, weight: 0.6 }}
      positions={expandedLinePoints.map((lp) => {
        return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
      })}
    />
  );
};

export default Line;
