import { useEffect, useState } from "react";
import { useVersionTag } from "../hooks/useVersionTag";
import { getComputedAreas, addComputedArea as _addComputedArea, updateComputedArea as _updateComputedArea, deleteComputedArea as _deleteComputedArea } from "./computedAreasApis";
import { ComputedArea } from "src/types/Types";

import * as _ from "lodash";

const useComputedAreas = () => {
  const versionTag = useVersionTag();
  const [computedAreas, setComputedAreas] = useState<ComputedArea[]>([]);
  const [selectedComputedArea, setSelectedComputedArea] = useState<ComputedArea | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getComputedAreas(versionTag).then((result) => {
      setComputedAreas(result);
      setLoading(false);
    });
  }, [versionTag]);

  const selectComputedArea = async (computedArea: ComputedArea) => {
    setSelectedComputedArea(computedArea);
  };

  const addComputedArea = async (computedArea: ComputedArea) => {
    const result = await _addComputedArea(computedArea, versionTag);
    setComputedAreas([...computedAreas, result]);
    setSelectedComputedArea(result);
    return result;
  };

  const updateComputedArea = async (computedArea: ComputedArea) => {
    const result = await _updateComputedArea(computedArea, versionTag);
    setComputedAreas([...computedAreas.filter((ca) => ca.id !== computedArea.id), result]);
    setSelectedComputedArea(result);
    return result;
  };

  const deleteComputedArea = async (computedArea: ComputedArea) => {
    const result = await _deleteComputedArea(computedArea, versionTag);
    setComputedAreas(computedAreas.filter((ca) => ca.id !== result));
  };

  return { loading, computedAreas, selectedComputedArea, selectComputedArea, addComputedArea, updateComputedArea, deleteComputedArea };
};

export default useComputedAreas;
