import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAirportsFromIds } from "src/apis/airports/airportsApi";
import { getAreasFromIds } from "src/apis/areas/areasApi";
import { getHoldingsFromIds } from "src/apis/holdings/holdingsApi";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getLinesFromIds } from "src/apis/lines/linesApi";
import { getMapTextsFromIds } from "src/apis/maptexts/mapTextsApi";
import { getPointsFromIds } from "src/apis/points/pointsApi";
import { getHighwayRampsFromIds } from "src/apis/swisstopo/highwayRampsApi";
import { getHighwaysFromIds } from "src/apis/swisstopo/highwaysApi";
import { getHighwayTunnelsFromIds } from "src/apis/swisstopo/highwayTunnelsApi";
import { getLakesFromIds } from "src/apis/swisstopo/lakesApi";
import { getMajorRoadsFromIds } from "src/apis/swisstopo/majorRoadsApi";
import { getRailwaysFromIds } from "src/apis/swisstopo/railwaysApi";
import { getUrbanAreasFromIds } from "src/apis/swisstopo/urbanAreasApi";
import { getSymbolWithColor } from "src/apis/symbols/symbolsApi";
import { getWaypointsFromIds } from "src/apis/waypoints/waypointsApi";
import MapViewer from "src/components/mapviewer/MapViewer";
import {
  Airport,
  Area,
  GraphicAttribute,
  Highway,
  HighwayRamp,
  HighwayTunnel,
  Holding,
  Lake,
  Line,
  MajorRoad,
  MapElementReference,
  MapText,
  Point,
  Railway,
  UrbanArea,
  Waypoint
} from "src/types/Types";

type MapEditorPreviewProps = {
  mapElementReferences: MapElementReference[];
  graphicAttributes: GraphicAttribute[];
};

type LineOptions = {
  color?: string;
  dashArray?: string | number[];
  weight?: number;
};

type AreaOptions = {
  color?: string;
  fillColor?: string;
  weight?: number;
};

type SymbolWithColor = { symbolName: string; color: string };

const parseLinePattern = (linePattern: string) => {
  if (linePattern === null || linePattern === undefined) {
    return "";
  }
  if (linePattern.startsWith("SOLID")) {
    return "";
  }
  if (linePattern.startsWith("USERSTYLE")) {
    const match = linePattern.match(/(USERSTYLE) (\d{2})(\d{2})/);
    if (match !== null) {
      return `${match[2]},${match[3]}`;
    }
  }
  return "";
};

const MapEditorPreview = (props: MapEditorPreviewProps) => {
  const versionTag = useVersionTag();
  const { graphicAttributes, mapElementReferences } = props;

  const [lines, setLines] = useState<Line[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [mapTexts, setMapTexts] = useState<MapText[]>([]);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const [points, setPoints] = useState<Point[]>([]);
  const [airports, setAirports] = useState<Airport[]>([]);
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [lakes, setLakes] = useState<Lake[]>([]);
  const [urbanAreas, setUrbanAreas] = useState<UrbanArea[]>([]);
  const [highways, setHighways] = useState<Highway[]>([]);
  const [highwayRamps, setHighwayRamps] = useState<HighwayRamp[]>([]);
  const [highwayTunnels, setHighwayTunnels] = useState<HighwayTunnel[]>([]);
  const [majorRoads, setMajorRoads] = useState<MajorRoad[]>([]);
  const [railways, setRailways] = useState<Railway[]>([]);

  const updateLinesFromRefs = (refs: MapElementReference[]) => {
    const lineRefs = refs.filter((ref) => ref.referenceType === "LINE");
    const lineColorsMap = new Map<number, LineOptions>();

    for (let i = 0; i < lineRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === lineRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        lineColorsMap.set(lineRefs[i].elementId, { color: graphicAttribute[0].color, dashArray: parseLinePattern(graphicAttribute[0].linePattern) });
      }
    }

    const lineIds = lineRefs.map((l) => l.elementId).toString();
    if (lineIds.length > 0) {
      getLinesFromIds(versionTag, lineIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = lineColorsMap.get(+result[i].id)?.color;
            result[i].dashArray = lineColorsMap.get(+result[i].id)?.dashArray;
          }
          setLines([...result]);
        })
        .catch(() => {
          toast.error("Failed to add lines!");
        });
    } else {
      setLines([]);
    }
  };

  const updateAreasFromRefs = (refs: MapElementReference[]) => {
    const areaRefs = refs.filter((ref) => ref.referenceType === "AREA");
    const areaIds = areaRefs.map((ref) => ref.elementId).toString();
    const areaColorsMap = new Map<number, AreaOptions>();

    for (let i = 0; i < areaRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === areaRefs[i].auxiliaryAttributes && ga.type === "AREA");
      if (graphicAttribute.length > 0) {
        areaColorsMap.set(areaRefs[i].elementId, { color: graphicAttribute[0].color });
      }
    }
    if (areaIds.length > 0) {
      getAreasFromIds(versionTag, areaIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = areaColorsMap.get(+result[i].id)?.color;
          }
          setAreas([...result]);
        })
        .catch(() => {
          toast.error("Failed to add lines!");
        });
    } else {
      setAreas([]);
    }
  };

  const updateMapTextsFromRefs = (refs: MapElementReference[]) => {
    const textRefs = refs.filter((ref) => ref.referenceType === "TEXT");
    const textColorsMap = new Map<number, string>();

    for (let i = 0; i < textRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === textRefs[i].attributes && ga.type === "TEXT");
      if (graphicAttribute.length > 0) {
        textColorsMap.set(textRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    const textIds = textRefs.map((t) => t.elementId).toString();
    if (textIds.length > 0) {
      getMapTextsFromIds(versionTag, textIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = textColorsMap.get(+result[i].id);
          }
          setMapTexts([...result]);
        })
        .catch(() => {
          toast.error("Failed to retrieve texts");
        });
    } else {
      setMapTexts([]);
    }
  };

  const updateWaypointsFromRefs = async (refs: MapElementReference[]) => {
    const waypointsRefs = refs.filter((ref) => ref.referenceType === "WAYPOINT");
    const waypointAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < waypointsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === waypointsRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        waypointAttributesMap.set(waypointsRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }
    const waypointIds = waypointsRefs.map((wp) => wp.elementId).toString();
    if (waypointIds.length > 0) {
      getWaypointsFromIds(versionTag, waypointIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = waypointAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setWaypoints(result);
        })
        .catch(() => {
          toast.error("failed to get waypoint ids!");
        });
    } else {
      setWaypoints([]);
    }
  };

  const updateHoldingsFromRefs = async (refs: MapElementReference[]) => {
    const holdingsRefs = refs.filter((ref) => ref.referenceType === "HOLDING");
    const holdinghAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < holdingsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === holdingsRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        holdinghAttributesMap.set(holdingsRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }
    const holdingIds = holdingsRefs.map((h) => h.elementId);
    if (holdingIds.length > 0) {
      getHoldingsFromIds(versionTag, holdingIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = holdinghAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setHoldings(result);
        })
        .catch(() => {
          toast.error("failed to get holding ids!");
        });
    } else {
      setHoldings([]);
    }
  };

  const updatePointsFromRefs = async (refs: MapElementReference[]) => {
    const pointRefs = mapElementReferences.filter((ref) => ref.referenceType === "POINT");
    const pointAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < pointRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === pointRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        pointAttributesMap.set(pointRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }

    const pointIds = pointRefs.map((p) => p.elementId).toString();
    if (pointIds.length > 0) {
      getPointsFromIds(versionTag, pointIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = pointAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setPoints(result);
        })
        .catch(() => {
          toast.error("failed to get point ids!");
        });
    } else {
      setPoints([]);
    }
  };

  const updateAirportsFromRefs = async (refs: MapElementReference[]) => {
    const airportRefs = refs.filter((ref) => ref.referenceType === "AIRPORT");
    const airportAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < airportRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === airportRefs[i].attributes && ga.type === "AIRPORT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        airportAttributesMap.set(airportRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }

    const airportIds = airportRefs.map((a) => a.elementId).toString();
    if (airportIds.length > 0) {
      getAirportsFromIds(versionTag, airportIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = airportAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setAirports(result);
        })
        .catch(() => {
          toast.error("failed to get point ids!");
        });
    } else {
      setAirports([]);
    }
  };

  const updateLakesFromRefs = (refs: MapElementReference[]) => {
    const lakeRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_LAKES");
    const lakeIds = lakeRefs.map((l) => l.elementId).toString();
    const lakeColorsMap = new Map<number, string>();

    for (let i = 0; i < lakeRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === lakeRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        lakeColorsMap.set(lakeRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (lakeIds.length > 0) {
      getLakesFromIds(versionTag, lakeIds).then((result) => {
        const lakesResult = result as Lake[];
        for (let i = 0; i < lakesResult.length; i++) {
          lakesResult[i].color = lakeColorsMap.get(+result[i].id);
        }
        setLakes(lakesResult);
      });
    } else {
      setLakes([]);
    }
  };

  const updateUrbanAreasFromRefs = (refs: MapElementReference[]) => {
    const urbanAreaRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_URBAN_AREAS");
    const urbanAreaIds = urbanAreaRefs.map((ua) => ua.elementId);
    const urbanAreaColorsMap = new Map<number, string>();

    for (let i = 0; i < urbanAreaRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === urbanAreaRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        urbanAreaColorsMap.set(urbanAreaRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (urbanAreaIds.length > 0) {
      getUrbanAreasFromIds(versionTag, urbanAreaIds).then((result) => {
        const urbanAreasResult = result as UrbanArea[];
        for (let i = 0; i < urbanAreasResult.length; i++) {
          urbanAreasResult[i].color = urbanAreaColorsMap.get(+result[i].id);
        }
        setUrbanAreas(urbanAreasResult);
      });
    } else {
      setUrbanAreas([]);
    }
  };

  const updateHighwaysFromRefs = (refs: MapElementReference[]) => {
    const highwayRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAYS");

    const highwayIds = highwayRefs.map((ua) => ua.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayRefs.length > 0) {
      getHighwaysFromIds(versionTag, highwayIds)
        .then((result) => {
          const highwaysResult = result as Highway[];
          for (let i = 0; i < highwaysResult.length; i++) {
            highwaysResult[i].color = colorMap.get(+highwaysResult[i].id);
          }
          setHighways(highwaysResult);
        })
        .catch(() => {
          toast.error("Failed to load highways");
        });
    } else {
      setHighways([]);
    }
  };

  const updateHighwayRampsFromRefs = (refs: MapElementReference[]) => {
    const highwayRampsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAY_RAMPS");

    const highwayRampsIds = highwayRampsRefs.map((ua) => ua.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayRampsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayRampsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayRampsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayRampsRefs.length > 0) {
      getHighwayRampsFromIds(versionTag, highwayRampsIds)
        .then((result) => {
          const highwayRampsResult = result as HighwayRamp[];
          for (let i = 0; i < highwayRampsResult.length; i++) {
            highwayRampsResult[i].color = colorMap.get(+highwayRampsResult[i].id);
          }
          setHighwayRamps(highwayRampsResult);
        })
        .catch(() => {
          toast.error("Failed to load highway ramps");
        });
    } else {
      setHighwayRamps([]);
    }
  };

  const updateHighwayTunnelsFromRefs = (refs: MapElementReference[]) => {
    const highwayTunnelsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAY_TUNNELS");

    const highwayTunnelsIds = highwayTunnelsRefs.map((ht) => ht.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayTunnelsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayTunnelsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayTunnelsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayTunnelsRefs.length > 0) {
      getHighwayTunnelsFromIds(versionTag, highwayTunnelsIds)
        .then((result) => {
          const highwayTunnelsResult = result as HighwayTunnel[];
          for (let i = 0; i < highwayTunnelsResult.length; i++) {
            highwayTunnelsResult[i].color = colorMap.get(+highwayTunnelsResult[i].id);
          }
          setHighwayTunnels(highwayTunnelsResult);
        })
        .catch(() => {
          toast.error("Failed to load highway tunnels");
        });
    } else {
      setHighwayTunnels([]);
    }
  };

  const updateMajorRoadsFromRefs = (refs: MapElementReference[]) => {
    const majorRoadsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_MAJOR_ROADS");

    const majorRoadsIds = majorRoadsRefs.map((r) => r.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < majorRoadsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === majorRoadsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(majorRoadsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (majorRoadsRefs.length > 0) {
      getMajorRoadsFromIds(versionTag, majorRoadsIds)
        .then((result) => {
          const majorRoadsResult = result as MajorRoad[];
          for (let i = 0; i < majorRoadsResult.length; i++) {
            majorRoadsResult[i].color = colorMap.get(+majorRoadsResult[i].id);
          }
          setMajorRoads(majorRoadsResult);
        })
        .catch(() => {
          toast.error("Failed to load major roads");
        });
    } else {
      setMajorRoads([]);
    }
  };

  const updateRailwaysFromRefs = (refs: MapElementReference[]) => {
    const railwayRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_RAILWAYS");

    const railwaysIds = railwayRefs.map((rw) => rw.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < railwayRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === railwayRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(railwayRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (railwayRefs.length > 0) {
      getRailwaysFromIds(versionTag, railwaysIds)
        .then((result) => {
          const railwaysResult = result as Railway[];
          for (let i = 0; i < railwaysResult.length; i++) {
            railwaysResult[i].color = colorMap.get(+railwaysResult[i].id);
          }
          setRailways(railwaysResult);
        })
        .catch(() => {
          toast.error("Failed to load railways");
        });
    } else {
      setRailways([]);
    }
  };

  useEffect(() => {
    updateLinesFromRefs(mapElementReferences);
    updateMapTextsFromRefs(mapElementReferences);
    updateWaypointsFromRefs(mapElementReferences);
    updatePointsFromRefs(mapElementReferences);
    updateAirportsFromRefs(mapElementReferences);
    updateHoldingsFromRefs(mapElementReferences);
    updateLakesFromRefs(mapElementReferences);
    updateAreasFromRefs(mapElementReferences);
    updateUrbanAreasFromRefs(mapElementReferences);
    updateHighwaysFromRefs(mapElementReferences);
    updateHighwayRampsFromRefs(mapElementReferences);
    updateHighwayTunnelsFromRefs(mapElementReferences);
    updateMajorRoadsFromRefs(mapElementReferences);
    updateRailwaysFromRefs(mapElementReferences);
  }, [mapElementReferences, graphicAttributes]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapViewer
        showTiles={false}
        lines={lines}
        areas={areas}
        texts={mapTexts}
        waypoints={waypoints}
        points={points}
        airports={airports}
        holdings={holdings}
        lakes={lakes}
        urbanAreas={urbanAreas}
        highways={highways}
        highwayRamps={highwayRamps}
        highwayTunnels={highwayTunnels}
        majorRoads={majorRoads}
        railways={railways}
      />
    </div>
  );
};

export default MapEditorPreview;
