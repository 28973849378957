import * as _ from "lodash";
import { PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type CountryOutlinesResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getCountryOutlines = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<CountryOutlinesResponse>("/swisstopo/country_outlines", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};
