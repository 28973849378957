import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Airport } from "src/types/Types";

import FlightIcon from "@mui/icons-material/Flight";
import { Marker, Tooltip } from "react-leaflet";

type AirportMarkerProps = {
  airport: Airport;
};

const AirportMarker = (props: AirportMarkerProps) => {
  const { airport } = props;

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: "#1565C0" }}>
        {airport.symbolImage ? (
          <div style={{ width: "15px", height: "15px", fontWeight: "bold" }}>
            <img src={`data:image/png;base64,${airport.symbolImage}`} />
          </div>
        ) : (
          <FlightIcon style={{ width: "15px", height: "15px", fontWeight: "bold" }} />
        )}
      </div>
    )
  });

  return (
    <>
      {airport.wgs84Latitude && airport.wgs84Longitude ? (
        <Marker icon={divIcon} position={[airport.wgs84Latitude, airport.wgs84Longitude]}>
          <Tooltip direction="left">{`[${airport.dmsLatitude} ${airport.dmsLongitude}]`}</Tooltip>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default AirportMarker;
