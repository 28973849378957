import * as _ from "lodash";
import { LatLng, NamedPolyLineWithAttributes, PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type RiversResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getRivers = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<RiversResponse>("/swisstopo/rivers", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};

export const groupByName = (data: PolyLineWithAttributes[]) => {
  return groupBy(data, (v) => v.attributes.name || "");
};

export const normalizeRivers = (data: PolyLineWithAttributes[]) => {
  const grouped: Record<string, PolyLineWithAttributes[]> = groupByName(data);

  const visited: Record<string, boolean> = {};

  const result: NamedPolyLineWithAttributes[] = [];
  for (let i = 0; i < data.length; i++) {
    const _name = data[i].attributes.name || "";
    if (!visited[_name]) {
      const auxPolylines = grouped[_name];

      const _sections: LatLng[][] = [];
      auxPolylines.forEach((ap) => {
        if (!_.isUndefined(ap.coordinates)) _sections.push(ap.coordinates);
        if (!_.isUndefined(ap.sections)) ap.sections.forEach((as) => _sections.push(as));
      });

      let pl: NamedPolyLineWithAttributes = {
        id: _.uniqueId(),
        name: _name,
        attributes: {},
        sections: _sections
      };

      result.push(pl);
      visited[_name] = true;
    }
  }

  return result;
};

const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
