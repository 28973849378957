import { FieldsValidator } from "./Validator";

class FrequencyValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  hasFrequencyError = (frequency: string) => {
    let hasError = false;
    if (frequency === undefined || frequency === null) {
      hasError = true;
    } else if (frequency.trim().length === 0) {
      hasError = true;
    } else if (Number.isNaN(+frequency)) {
      hasError = true;
    }
    this.validationMap.set("frequency", hasError);
    return hasError;
  };

  validateField = (fieldName: string, fieldValue: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name":
        if (fieldValue === undefined || fieldValue === null) {
          this.validationMap.set("name", true);
          return "Invalid name";
        } else if (fieldValue.trim().length === 0) {
          this.validationMap.set("name", true);
          return "Invalid name";
        }
        return "";
      case "frequency":
        if (fieldName === undefined || fieldName === null) {
          this.validationMap.set("fieldName", true);
          return "Invalid frequency";
        } else if (fieldName.trim().length === 0) {
          this.validationMap.set("fieldName", true);
          return "Invalid frequency";
        } else if (Number.isNaN(+fieldName)) {
          this.validationMap.set("fieldName", true);
          return "Invalid frequency";
        }
        return "";
      default:
        return "";
    }
  };
}

export default FrequencyValidator;
