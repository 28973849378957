import { useEffect, useState } from "react";
import { Polygon } from "react-leaflet";
import { Area as AreaType, AreaPoint } from "src/types/Types";

import useArc from "src/apis/lines/useArc";
import { LatLng } from "leaflet";
import { isUndefined } from "lodash";

type LineProps = {
  area: AreaType;
};

export const Area = (props: LineProps) => {
  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc(100);
  const defaultColor = "#3388ff";

  const { area } = props;
  const [areaPoints, setAreaPoints] = useState<AreaPoint[]>([]);

  useEffect(() => {
    const areaPoints = area?.areaPoints || [];
    setAreaPoints(areaPoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  }, [area]);

  useEffect(() => {
    setSortedLinePointsForExpansion(areaPoints);
  }, [areaPoints]);

  return (
    <Polygon
      key={`${area.id}${area.color ? area.color : ""}`}
      pathOptions={{
        color: area.color ? area.color : defaultColor,
        /* TODO:           
          >compute fill color. Probably we need some utility function that will:
          - return undefined if no fill color is specified for an area
          - convert rgb[] to a string (in case fillColor for an area is declared as number[]. It shall return string in format '#xxxxxx') 
          - return input string if fillColor in area is defined as a string
        */
        // fillColor: formatAreaFillColor(area.fillColor),
        //fillColor: "#ffff00",
        weight: 1,
        fill: !isUndefined(area.fillColor)
      }}
      positions={expandedLinePoints.map((lp) => {
        return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
      })}
    />
  );
};

export default Area;
