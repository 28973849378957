import Split from "react-split";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import { AdditionalInformation, ComputedArea } from "src/types/Types";

import useComputedAreas from "src/apis/computedareas/useComputedAreas";
import useComputedAreaColumns from "src/columnsgenerators/useComputedAreaColumns";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import ComputedAreaValidator from "src/validators/ComputedAreaValidator";

import GenericDataGrid from "src/components/datagrids/GenericDataGrid";

export type ComputedAreasResponse = {
  data: ComputedArea[];
  errorMessage: string;
};

const ComputedAreas = () => {
  const versionTag = useVersionTag();
  const { loading, computedAreas, selectedComputedArea, selectComputedArea, addComputedArea, updateComputedArea, deleteComputedArea } = useComputedAreas();
  const { getColumns } = useComputedAreaColumns();

  const onComputedAreaSelected = (computedArea: ComputedArea) => {
    selectComputedArea(computedArea);
  };

  const createEmptyRow = () => {
    return {
      id: -1,
      name: "",
      description: "",
      mergedAreas: [],
      closures: [],
      smoothing: 0,
      userTags: [],
      additionalInformation: []
    };
  };

  const onCreateComputedArea = (computedArea: ComputedArea) => {
    return addComputedArea(computedArea);
  };

  const onUpdateComputedArea = (computedArea: ComputedArea) => {
    return updateComputedArea(computedArea);
  };

  const onDeleteComputedArea = (computedArea: ComputedArea) => {
    return deleteComputedArea(computedArea);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Computed areas - {versionTag}</h2>
      <div style={{ height: "90%" }}>
        <GenericDataGrid<ComputedArea>
          loading={loading}
          sortModel={[{ field: "name", sort: "asc" }]}
          columnsGenerator={getColumns}
          createEmptyRow={createEmptyRow}
          data={computedAreas}
          selectedRow={selectedComputedArea}
          validator={new ComputedAreaValidator()}
          onSelectRow={onComputedAreaSelected}
          onCreateRow={onCreateComputedArea}
          onUpdateRow={onUpdateComputedArea}
          onDeleteRow={onDeleteComputedArea}
        />
      </div>
    </div>
  );
};

export default ComputedAreas;
