import React, { useEffect, useRef, useState } from "react";

import L, { LatLng, LatLngExpression } from "leaflet";

import { CircleMarker, LayersControl, MapContainer, Polygon, Polyline, TileLayer, Tooltip, LayerGroup } from "react-leaflet";
import Fullscreen from "react-leaflet-fullscreen-plugin";

import "leaflet/dist/leaflet.css";

import { useWaypoints } from "src/apis/waypoints/useWaypoints";

import { isUndefined, uniqueId } from "lodash";
import { Wgs84LatLng } from "src/apis/waypoints/waypointsApi";
import {
  Airport,
  AirwayPoint,
  Area,
  AreaPoint,
  Highway,
  HighwayRamp,
  HighwayTunnel,
  Holding,
  Lake,
  Line,
  LinePoint,
  MajorRoad,
  MapText,
  NamedPolygons,
  Point,
  PolyLineWithAttributes,
  Railway,
  UrbanArea,
  Waypoint
} from "src/types/Types";
import PointMarker from "./layers/PointMarker";
import TextMarker from "./layers/TextMarker";
import WaypointMarker from "./layers/WaypointMarker";
import SequentialPointLabel from "./layers/SequentialPointLabel";
import AirportMarker from "./layers/AirportMarker";
import Lakes from "./layers/Lakes";
import UrbanAreas from "./layers/UrbanAreas";
import { Line as LineLayer } from "./layers/Line";
import { Area as AreaLayer } from "./layers/Area";
import Highways from "./layers/Highways";
import MajorRoads from "./layers/MajorRoads";
import HighwayRamps from "./layers/HighwayRamps";
import HighwayTunnels from "./layers/HighwayTunnels";
import HoldingMarker from "./layers/HoldingMarker";
import Railways from "./layers/Railways";

const cartoMapsUrlLightNoLabels = "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png";

export type MapViewerHandle = {
  invalidateSize: () => void;
};

export type NamedCoordinate = {
  name: string;
  wgs84LatLng: Wgs84LatLng;
};

type MapViewerProps = {
  showTiles?: boolean;
  texts?: MapText[];
  waypoints?: Waypoint[];
  points?: Point[];
  airports?: Airport[];
  holdings?: Holding[];
  namedCoordinate?: NamedCoordinate;
  airwayPoints?: AirwayPoint[];
  lines?: Line[];
  linePoints?: LinePoint[];
  areas?: Area[];
  areaPoints?: AreaPoint[];
  geoSelectedNamedPolygon?: NamedPolygons;
  geoNamedPolygons?: NamedPolygons[];
  geoSelectedPolyLine?: PolyLineWithAttributes;
  geoPolylines?: PolyLineWithAttributes[];
  lakes?: Lake[];
  urbanAreas?: UrbanArea[];
  highways?: Highway[];
  highwayRamps?: HighwayRamp[];
  highwayTunnels?: HighwayTunnel[];
  majorRoads?: MajorRoad[];
  railways?: Railway[];
};

const MapViewer: React.ForwardRefRenderFunction<MapViewerHandle, MapViewerProps> = (props, forwardedRef) => {
  const [map, setMap] = useState<L.Map>();
  const mapCenter: LatLngExpression = [46.821488, 7.99639];

  const {
    namedCoordinate,
    waypoints,
    points,
    airports,
    holdings,
    airwayPoints,
    texts,
    lines,
    linePoints,
    areas,
    areaPoints,
    geoSelectedNamedPolygon,
    geoNamedPolygons,
    geoSelectedPolyLine,
    geoPolylines,
    lakes,
    urbanAreas,
    highways,
    highwayRamps,
    highwayTunnels,
    majorRoads,
    railways,
    showTiles = true
  } = props;
  const { waypoints: allWaypoints } = useWaypoints();
  const [airwayPointsCoords, setAirwayPointsCoords] = useState<Array<LatLng>>([]);
  const polygonRef = useRef<L.Polygon<any>>(null);

  React.useImperativeHandle(forwardedRef, () => ({
    invalidateSize() {
      if (!isUndefined(map)) {
        map.invalidateSize();
      }
    }
  }));

  useEffect(() => {
    if (!isUndefined(waypoints) && waypoints.length > 0 && !isUndefined(map)) {
      map.flyTo(new LatLng(waypoints[0].wgs84Latitude, waypoints[0].wgs84Longitude));
    }
  }, [waypoints]);

  useEffect(() => {
    if (!isUndefined(linePoints) && linePoints.length > 0 && !isUndefined(map)) {
      map.flyTo(new LatLng(linePoints[0].wgs84Latitude, linePoints[0].wgs84Longitude));
    }
  }, [linePoints]);

  useEffect(() => {
    if (!isUndefined(texts) && texts.length > 0 && !isUndefined(map)) {
      map.flyTo(new LatLng(texts[0].wgs84Latitude, texts[0].wgs84Longitude));
    }
  }, [texts]);

  useEffect(() => {
    if (!isUndefined(geoSelectedNamedPolygon) && geoSelectedNamedPolygon.polygons.length > 0 && !isUndefined(map)) {
      map.flyTo(new LatLng(geoSelectedNamedPolygon.polygons[0].coordinates[0].wgs84Latitude, geoSelectedNamedPolygon.polygons[0].coordinates[0].wgs84Longitude));
    }
  }, [geoSelectedNamedPolygon]);

  useEffect(() => {
    if (!isUndefined(geoSelectedPolyLine) && !isUndefined(geoSelectedPolyLine.sections) && geoSelectedPolyLine.sections.length > 0 && !isUndefined(map)) {
      map.flyTo(new LatLng(geoSelectedPolyLine.sections[0][0].wgs84Latitude, geoSelectedPolyLine.sections[0][0].wgs84Longitude));
    }
  }, [geoSelectedPolyLine]);

  useEffect(() => {
    if (areaPoints && areaPoints.length > 0 && !isUndefined(map) && polygonRef.current) {
      map.flyTo(polygonRef.current.getCenter());
    }
  }, [areaPoints]);

  useEffect(() => {
    if (!isUndefined(airwayPoints) && airwayPoints.length > 0 && allWaypoints.length > 0) {
      setAirwayPointsCoords(
        airwayPoints
          .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
          .map((ap) => {
            const waypoint = allWaypoints.filter((wp) => wp.name === ap.waypointName)[0];
            return new LatLng(waypoint.wgs84Latitude, waypoint.wgs84Longitude);
          })
      );
    } else {
      setAirwayPointsCoords([]);
    }
  }, [airwayPoints, allWaypoints]);

  useEffect(() => {
    if (airwayPointsCoords.length > 0 && !isUndefined(map)) {
      map.flyTo(airwayPointsCoords[0]);
    }
  }, [airwayPointsCoords]);

  useEffect(() => {
    if (!isUndefined(namedCoordinate) && !isUndefined(map)) {
      map.flyTo(new LatLng(namedCoordinate.wgs84LatLng.wgs84Latitude, namedCoordinate.wgs84LatLng.wgs84Longitude));
    }
  }, [namedCoordinate]);

  const backgroundColor = showTiles === true ? "#ddd;" : "#000";

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer style={{ height: "100%", width: "100%", backgroundColor: backgroundColor }} center={mapCenter} zoom={8} whenCreated={(map) => setMap(map)} preferCanvas>
        {showTiles === true && <TileLayer url={cartoMapsUrlLightNoLabels} />}

        {(linePoints || areaPoints || airwayPointsCoords.length > 0) && (
          <LayersControl position="bottomleft">
            <LayersControl.Overlay name="Show labels" checked={false}>
              {linePoints && (
                <LayerGroup>
                  {linePoints
                    .filter((lp) => !lp.computed)
                    .map((lp) => {
                      return <SequentialPointLabel point={lp} />;
                    })}
                </LayerGroup>
              )}
              {areaPoints && (
                <LayerGroup>
                  {areaPoints
                    .filter((ap) => !ap.computed)
                    .map((ap) => {
                      return <SequentialPointLabel point={ap} />;
                    })}
                </LayerGroup>
              )}
              {airwayPointsCoords.length > 0 && (
                <LayerGroup>
                  {airwayPointsCoords.map((ap, index) => {
                    return (
                      <SequentialPointLabel
                        point={{
                          id: uniqueId(),
                          sequence: index + 1,
                          originalSequence: index + 1,
                          wgs84Latitude: ap.lat,
                          wgs84Longitude: ap.lng,
                          curvature: "0",
                          inverted: false
                        }}
                      />
                    );
                  })}
                </LayerGroup>
              )}
            </LayersControl.Overlay>
          </LayersControl>
        )}

        {texts?.map((t) => {
          return <TextMarker key={`${t.id}${t.color ? t.color : ""}`} text={t} color={t.color} />;
        })}
        {airwayPointsCoords.length > 0 && <Polyline positions={airwayPointsCoords} />}
        {waypoints?.map((wp) => {
          return <WaypointMarker waypoint={wp} />;
        })}
        {points?.map((p) => {
          return <PointMarker point={p} />;
        })}
        {airports?.map((a) => {
          return <AirportMarker airport={a} />;
        })}
        {holdings?.map((h) => {
          return <HoldingMarker holding={h} />;
        })}
        {namedCoordinate && (
          <CircleMarker center={[namedCoordinate.wgs84LatLng.wgs84Latitude, namedCoordinate.wgs84LatLng.wgs84Longitude]} radius={3}>
            {namedCoordinate.name && <Tooltip direction="left" permanent>{`${namedCoordinate.name}`}</Tooltip>}
          </CircleMarker>
        )}
        {lines && lines.map((l) => <LineLayer line={l} />)}
        {linePoints && (
          <Polyline
            positions={linePoints
              .slice()
              .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
              .map((lp) => {
                return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
              })}
          />
        )}
        {areaPoints && (
          <Polygon
            ref={polygonRef}
            positions={areaPoints
              .slice()
              .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
              .map((ap) => {
                return new LatLng(ap.wgs84Latitude, ap.wgs84Longitude);
              })}
          />
        )}
        {areas && areas.map((a) => <AreaLayer area={a} />)}

        {isUndefined(geoSelectedNamedPolygon) &&
          geoNamedPolygons?.map((lake) => {
            return lake.polygons.map((p) => {
              return (
                <Polygon
                  positions={p.coordinates.slice().map((ap) => {
                    return new LatLng(ap.wgs84Latitude, ap.wgs84Longitude);
                  })}
                />
              );
            });
          })}
        {geoSelectedNamedPolygon?.polygons.map((p) => {
          return (
            <Polygon
              positions={p.coordinates.slice().map((ap) => {
                return new LatLng(ap.wgs84Latitude, ap.wgs84Longitude);
              })}
            />
          );
        })}
        {geoPolylines
          ?.filter((co) => !isUndefined(co.coordinates))
          .map((co) => {
            return (
              co.coordinates && (
                <Polyline
                  positions={co.coordinates.slice().map((lp) => {
                    return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
                  })}
                />
              )
            );
          })}
        {geoPolylines
          ?.filter((co) => !isUndefined(co.sections))
          .map((co) => {
            return (
              co.sections &&
              co.sections.map((s) => (
                <Polyline
                  positions={s.slice().map((sp) => {
                    return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
                  })}
                />
              ))
            );
          })}
        {geoSelectedPolyLine &&
          geoSelectedPolyLine.sections &&
          geoSelectedPolyLine?.sections.map((co) => {
            return (
              <Polyline
                positions={co.slice().map((lp) => {
                  return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
                })}
              />
            );
          })}
        {lakes && <Lakes lakes={lakes} />}
        {urbanAreas && <UrbanAreas urbanAreas={urbanAreas} />}
        {highways && <Highways highways={highways} />}
        {highwayRamps && <HighwayRamps highwayRamps={highwayRamps} />}
        {highwayTunnels && <HighwayTunnels highwayTunnels={highwayTunnels} />}
        {majorRoads && <MajorRoads majorRoads={majorRoads} />}
        {railways && <Railways railways={railways} />}
        <Fullscreen
          eventHandlers={{
            enterFullscreen: (event) => console.log("entered fullscreen", event),
            exitFullscreen: (event) => console.log("exited fullscreen", event)
          }}
        />
      </MapContainer>
    </div>
  );
};

export default React.forwardRef(MapViewer);
