import React, { useEffect, useRef, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { GridRowId, GridRowParams, GridSelectionModel } from "@mui/x-data-grid-pro";

import AddIcon from "@mui/icons-material/ArrowDownward";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import Split from "react-split";

import { MapViewerHandle } from "src/components/mapviewer/MapViewer";
import MapReferencesGrid from "../datagrids/MapReferencesGrid";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { MapElementReferencesUpdates, MenuItem } from "src/types/Types";

import { toast } from "react-toastify";
import useMapEditorHandler from "./useMapEditorHandler";
import PathSelector from "src/components/selectors/pathselector/PathSelector";
import MapCodeFieldEditor from "src/components/editors/MapCodeFieldEditor";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import ClientTypesSelector from "src/components/selectors/clienttypesselector/ClientTypesSelector";
import { ClientType } from "src/pages/clienttypes/ClientTypes";
import SearchFilters from "./SearchFilters";
import { LoadingButton } from "@mui/lab";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveMapElementReferencesDialog from "./RemoveMapElementReferencesDialog";
import ConfirmChangesDialog from "./ConfirmChangesDialog";

import MapEditorPreview from "./MapEditorPreview";

type MapEditorProps = {
  open: boolean;
  paths: string[];
  clientTypes: ClientType[];
  getNextSequenceValue: () => Promise<number>;
  createMap: (map: MenuItem) => Promise<MenuItem>;
  updateMap: (map: MenuItem) => Promise<MenuItem>;
  onMapCreated: (map: MenuItem) => void;
  onMapUpdated: (map: MenuItem) => void;
  onMapCanceled: () => void;
  map?: MenuItem;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const MapEditor = (props: MapEditorProps) => {
  const { paths, clientTypes, getNextSequenceValue, createMap, updateMap, onMapCreated, onMapUpdated, onMapCanceled, open, map } = props;

  const onSuccess = (_map: MenuItem) => {
    if (map) {
      onMapUpdated(_map);
    } else {
      onMapCreated(_map);
    }
  };

  const {
    formHandler,
    availableReferences,
    addedReferences,
    loadingReferences,
    saving,
    addedReferencesSelectionModel,
    graphicAttributes,
    graphicAttributeType,
    auxiliaryGraphicAttributeType,
    selectedMapElementTypes,
    selectedUserTag,
    clearSearch,
    clearCurrentReferences,
    addReferencesToMap,
    addExistingReferences,
    removeElementReference,
    removeElementReferences,
    onAddedReferencesSelectionModelChange,
    onGraphicAttributeChange,
    onAuxiliaryGraphicAttributeChange,
    onSelectedMapElementTypesChange,
    onSelectedUserTagChange,
    onAirwayStretchChange,
    computeMapElementReferencesUpdates
  } = useMapEditorHandler(createMap, updateMap, onSuccess, map);

  const [gridSelectionModel, setGridSelectionModel] = useState<GridSelectionModel>([]);
  const mapViewerRef = useRef<MapViewerHandle>(null);
  const [addingReferences, setAddingReferences] = useState(false);
  const [, setSelectedClientTypes] = useState<ClientType[]>([]);

  const [saveMapEnabled, setSaveMapEnabled] = useState(true);

  const [showRemoveReferencesDialog, setShowRemoveReferencesDialog] = useState(false);

  const [mapElementUpdates, setMapElementUpdates] = useState<MapElementReferencesUpdates>();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onRowEditStart = () => {
    setSaveMapEnabled(false);
  };

  const onRowEditStop = () => {
    setSaveMapEnabled(true);
  };

  const onCancelSaveMap = () => {
    setShowConfirmation(false);
  };

  const onConfirmSaveMap = () => {
    setShowConfirmation(false);
    formHandler.submitForm();
  };

  const onSaveMap = () => {
    if (map) {
      computeMapElementReferencesUpdates().then((result) => {
        setMapElementUpdates(result);
        setShowConfirmation(true);
      });
    } else {
      formHandler.submitForm();
    }
  };

  const onSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    setGridSelectionModel(newSelectionModel);
  };

  const onClearSearch = () => {
    // clearAvailableReferences();
    clearSearch();
  };

  const onChangeUserTag = (newValue: string | null) => {
    onSelectedUserTagChange(newValue);
  };

  const onAddReferencesToMap = (referenceIds: GridSelectionModel) => {
    setAddingReferences(true);
    addReferencesToMap(availableReferences.filter((r) => referenceIds.includes(r.id)))
      .catch((error) => {
        if (error.errorMessage) {
          toast.error(error.errorMessage);
        } else {
          toast.error(JSON.stringify(error));
        }
      })
      .finally(() => {
        setAddingReferences(false);
      });
  };

  const onRemoveReferenceFromMap = (referenceId: GridRowId) => {
    return Promise.resolve(removeElementReference(referenceId));
  };

  const onConfirmRemoveSelectedReferences = () => {
    removeElementReferences(addedReferencesSelectionModel);
    setShowRemoveReferencesDialog(false);
  };

  const onCancelRemoveMapElementReferences = () => {
    setShowRemoveReferencesDialog(false);
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    onAddReferencesToMap([params.row.id]);
  };

  const parseMapPath = (path: string[]) => {
    if (path.length === 1) {
      return "/";
    } else {
      const newPath = path.slice(0, path.length - 1);
      return newPath.join("/");
    }
  };

  useEffect(() => {
    formHandler.resetForm();
    clearSearch();
    clearCurrentReferences();
    setSelectedClientTypes([]);
    if (open) {
      if (map) {
        formHandler.setFieldValue("code", map.code?.toString());
        formHandler.setFieldValue("path", parseMapPath(map.path));
        formHandler.setFieldValue("name", map.name);
        formHandler.setFieldValue("userTags", map.userTags);
        formHandler.setFieldValue(
          "clientTypes",
          clientTypes.filter((ct) => map.clientTypes.includes(ct.name))
        );
        addExistingReferences(map.mapElementReferences);
      } else {
        getNextSequenceValue().then((value) => {
          formHandler.setFieldValue("code", value.toString());
        });
      }
    }
  }, [open, map]);

  const onChangePath = (newPath: string | null) => {
    formHandler.setFieldValue("path", newPath);
  };

  const onChangeCode = (newCode: string) => {
    formHandler.setFieldValue("code", newCode);
  };

  const onChangeName = (newName: string) => {
    formHandler.setFieldValue("name", newName);
  };

  const onChangeClientTypes = (newValues: ClientType[]) => {
    formHandler.setFieldValue("clientTypes", newValues);
  };

  const onChangeUserTags = (newValues: string[]) => {
    formHandler.setFieldValue("userTags", newValues);
  };

  const onShowRemoveSelectedReferences = () => {
    setShowRemoveReferencesDialog(true);
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <EditLocationAltIcon />
          </div>
          <div>
            <Typography style={{ marginLeft: "10px" }}>Map Editor</Typography>
          </div>
          <div style={{ marginLeft: "30px", width: "360px", marginBottom: "2px" }}>
            <PathSelector
              paths={paths}
              value={formHandler.values.path}
              onChange={onChangePath}
              error={formHandler.touched.path && Boolean(formHandler.errors.path)}
              helperText={formHandler.touched.path && formHandler.errors.path}
            />
          </div>
          <div style={{ marginLeft: 20, flex: 0.2 }}>
            <MapCodeFieldEditor
              value={formHandler.values.code}
              onChange={onChangeCode}
              error={formHandler.touched.path && Boolean(formHandler.errors.code)}
              helperText={formHandler.touched.path && formHandler.errors.code}
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <TextFieldEditor
              label="Name"
              value={formHandler.values.name}
              onChange={onChangeName}
              hasError={formHandler.touched.path && Boolean(formHandler.errors.name)}
              helperText={formHandler.touched.path && formHandler.errors.name}
            />
          </div>
          <div style={{ marginLeft: "10px", width: "400px", marginBottom: "2px" }}>
            <UserTagSelector currentSelection={formHandler.values.userTags} onChange={onChangeUserTags} />
          </div>
          <div style={{ marginLeft: "10px", width: "400px", marginBottom: "2px" }}>
            <ClientTypesSelector clientTypes={clientTypes} currentSelection={formHandler.values.clientTypes} onChangeSelection={onChangeClientTypes} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {showConfirmation && <ConfirmChangesDialog mapElementUpdates={mapElementUpdates} onConfirm={onConfirmSaveMap} onCancel={onCancelSaveMap} />}
        <Split
          style={{ height: "100%", display: "flex", flexDirection: "row" }}
          direction="horizontal"
          sizes={[57, 43]}
          onDragEnd={() => {
            if (mapViewerRef !== null) {
              mapViewerRef.current?.invalidateSize();
            }
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", height: "100%", marginRight: "5px" }}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
              <div>Search filters</div>
              <SearchFilters
                currentElementTypesSelection={selectedMapElementTypes}
                currentUserTagSelection={selectedUserTag}
                onChangeElementTypes={onSelectedMapElementTypesChange}
                onChangeUserTag={onChangeUserTag}
                onClear={onClearSearch}
              />
              <div style={{ flex: 1 }}>
                <MapReferencesGrid
                  loading={loadingReferences}
                  mapElementReferences={availableReferences}
                  onRowDoubleClick={onRowDoubleClick}
                  onSelectionModelChange={onSelectionModelChange}
                  checkBoxSelection
                  showToolbar
                  showNameFilter
                  showAttributes={false}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10, justifyContent: "center", alignItems: "center" }}>
                <div style={{ marginTop: 10 }}>
                  <LoadingButton
                    disabled={!saveMapEnabled}
                    startIcon={<AddIcon />}
                    loading={addingReferences}
                    variant="outlined"
                    loadingPosition="start"
                    onClick={() => {
                      onAddReferencesToMap(gridSelectionModel);
                    }}
                  >
                    Add to map
                  </LoadingButton>
                </div>
                <div style={{ marginLeft: "5px", marginTop: 10 }}>
                  <Button variant="outlined" startIcon={<DeleteOutlineIcon />} onClick={onShowRemoveSelectedReferences} disabled={addedReferencesSelectionModel.length === 0}>
                    Remove selected
                  </Button>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                {showRemoveReferencesDialog && <RemoveMapElementReferencesDialog onConfirm={onConfirmRemoveSelectedReferences} onCancel={onCancelRemoveMapElementReferences} />}
                <MapReferencesGrid
                  checkBoxSelection
                  selectionModel={addedReferencesSelectionModel}
                  onSelectionModelChange={onAddedReferencesSelectionModelChange}
                  loading={false}
                  mapElementReferences={addedReferences}
                  showAttributes={true}
                  onDelete={onRemoveReferenceFromMap}
                  showToolbar
                  showGraphicAttributeSelector
                  isEditable
                  graphicAttributeType={graphicAttributeType}
                  auxiliaryGraphicAttributeType={auxiliaryGraphicAttributeType}
                  onGraphicAttributeChange={onGraphicAttributeChange}
                  onAuxiliaryGraphicAttributeChange={onAuxiliaryGraphicAttributeChange}
                  onAirwayStretchChange={onAirwayStretchChange}
                  onRowEditStart={onRowEditStart}
                  onRowEditStop={onRowEditStop}
                />
              </div>
            </div>
          </div>
          <div style={{ height: "100%", marginLeft: "5px" }}>
            <MapEditorPreview graphicAttributes={graphicAttributes} mapElementReferences={addedReferences} />
          </div>
        </Split>
      </DialogContent>
      <DialogActions>
        <Button onClick={onMapCanceled}>Cancel</Button>
        <LoadingButton loading={saving} autoFocus variant="contained" onClick={onSaveMap} disabled={!saveMapEnabled}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MapEditor;
