import { Avatar, Box, Button, Container, createTheme, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography } from "@mui/material";
import React, { useState } from "react";

import axios, { AxiosError } from "axios";
import { toast, ToastContainer } from "react-toastify";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Redirect } from "react-router";
import { initAPIClient } from "src/apis/apiClient";
import { useAppContext } from "src/appcontext/AppContext";
import { appConfiguration } from "src/types/appconfig";
import { VersionsResponse } from "../versions/Versions";

const theme = createTheme();

type AuthenticationToken = {
  token: string;
};

const SignIn = () => {
  const appContext = useAppContext();
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const apiUrl = appConfiguration.REACT_APP_API_URL;
  const wsUrl = appConfiguration.REACT_APP_WS_URL;

  const onUsernameChange = (event: any) => {
    setUsername(event.target.value);
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const onSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axios
      .post<AuthenticationToken>(`${apiUrl}/signIn`, { username: username, password: password })
      .then((response) => {
        const ws = undefined; //new WebSocket(wsUrl ? wsUrl : "");

        const apiClient = initAPIClient(apiUrl!, 180000, response.data.token);
        apiClient.get<VersionsResponse>("/versions").then((result) => {
          appContext.dispatch({
            type: "setUserAuthentication",
            payload: {
              apiClient: apiClient,
              ws: ws,
              token: response.data.token,
              versions: result.data.data
            }
          });
        });
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          toast("Invalid username or password", { type: "error" });
        }
      });
  };

  if (appContext.state.authenticationToken !== undefined) {
    return <Redirect to={"/"} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <ToastContainer />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onSignIn} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth onChange={onUsernameChange} id="username" label="username" name="username" autoFocus />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={onPasswordChange}
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
