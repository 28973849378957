import React, { useEffect, useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { DataGridPro, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";
import { SidStarPoint, StandardInstrumentDeparture } from "src/types/Types";

type AirportSIDsProps = {
  sids: StandardInstrumentDeparture[];
};

const AirportSIDs = (props: AirportSIDsProps) => {
  const apiRef = useGridApiRef();
  const { sids } = props;

  const [points, setPoints] = useState<SidStarPoint[]>([]);

  const [currentTab, setCurrentTab] = useState("sidpoints");

  const onShowPoints = (rowParams: GridRowParams<StandardInstrumentDeparture>) => {
    setPoints(rowParams.row.points);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setPoints([]);
  }, [sids]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", editable: true },
    {
      field: "shortName",
      headerName: "Short name",
      width: 200,
      editable: true
    },
    { field: "runwayDesignator", headerName: "Runway", width: 120, editable: true },
    { field: "equipment", headerName: "Equipment", width: 150, editable: true },
    { field: "entryLevel", headerName: "EFL", editable: true },
    {
      field: "flightLevelCorrection",
      headerName: "FL correction",
      editable: true
    },
    { field: "noLevelAssignment", headerName: "No Level assign.", editable: true, type: "boolean" },
    {
      field: "initialTurn",
      headerName: "Initial turn",
      editable: true
    },
    {
      field: "towerEnabled",
      headerName: "Tower enabled",
      editable: true,
      type: "boolean"
    },
    {
      field: "approachDescription",
      headerName: "Approach description",
      editable: true
    },
    {
      field: "runwayLossTime",
      headerName: "Runway loss time",
      editable: true
    },
    {
      field: "propsAlternative",
      headerName: "Props",
      editable: true,
      type: "boolean"
    },
    {
      field: "displayOrder",
      headerName: "Display order",
      editable: true
    },
    {
      field: "climbClearance",
      headerName: "Climb clearance",
      editable: true
    },
    {
      field: "nonStandard",
      headerName: "Non standard",
      editable: true,
      type: "boolean"
    },
    {
      field: "dclCapable",
      headerName: "DCL capable",
      editable: true,
      type: "boolean"
    },

    { field: "userTags", headerName: "User tags", editable: true }
  ];

  const sidPointColumns = [
    { field: "sequence", headerName: "Sequence", width: 200 },
    { field: "waypoint", headerName: "Way point", width: 200 },
    { field: "elevation", headerName: "Elevation", width: 200 },
    { field: "nearPoint", headerName: "Near point", width: 200 },
    { field: "userTags", headerName: "User tags", width: 200 }
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ width: "100%", flex: 1 }}>
        <DataGridPro
          apiRef={apiRef}
          density="compact"
          editMode="row"
          columns={columns}
          rows={sids}
          onRowClick={onShowPoints}
          initialState={{
            pinnedColumns: { left: ["name"] },
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }]
            }
          }}
        />
      </div>
      <div style={{ flex: 1.1, marginBottom: "25px" }}>
        <TabContext value={currentTab}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab value="sidpoints" label={"SID points"} />
          </Tabs>
          <TabPanel value="sidpoints" style={{ height: "80%" }}>
            <DataGridPro
              density="compact"
              columns={sidPointColumns}
              rows={points}
              initialState={{
                sorting: {
                  sortModel: [{ field: "sequence", sort: "asc" }]
                }
              }}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default AirportSIDs;
